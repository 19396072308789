<template>
  
  <div class="grid">
    <Toast />
        <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(1,Mathematics,Mathematics_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"><b>Mathematics</b></span>
              <div class="text-900 font-medium text-xl"> {{ Mathematics }}</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(2,Physics,Physics_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"><b>Physics</b></span>
              <div class="text-900 font-medium text-xl">{{ Physics }}</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-database text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(3,Chemistry,Chemistry_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"><b>Chemistry</b></span>
              <div class="text-900 font-medium text-xl">{{ Chemistry }}</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-server text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(4,Botany,Botany_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"><b>Botany</b></span>
              <div class="text-900 font-medium text-xl">{{ Botany }}</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-briefcase text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(5,Zoology,Zoology_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"><b>Zoology</b> </span>
              <div class="text-900 font-medium text-xl">{{ Zoology }}</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-sliders-v text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-4">
      <div v-on:click="go_to_ui(6,Introduction_From_Project_Manager,Introduction_From_Project_Manager_id)">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-4">
            <div>
              <span class="block text-500 font-medium mb-3"
                ><b>Introduction From Project Manager</b> </span
              >
              <div class="text-900 font-medium text-xl">
                {{ Introduction_From_Project_Manager }}
              </div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-fw pi-book text-blue-500 text-xl"></i>
            </div>
          </div>
          <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from "../service/ProductService";
import apis from "@/apis";
export default {
  data() {
    return {
      Mathematics: 0,
      Physics: 0,
      Chemistry: 0,
      Botany: 0,
      Zoology: 0,
      Introduction_From_Project_Manager: 0,
      Mathematics_id: 0,
      Physics_id: 0,
      Chemistry_id: 0,
      Botany_id: 0,
      Zoology_id: 0,
      Introduction_From_Project_Manager_id: 0,
      isLoadingModel:false,
			fullPage:true,
    };
  },
  async created() {
    this.isLoadingModel=true;
    var data = {
      type: "studymaterial",
      MOBILE: localStorage.getItem("mobile"),
    };

    var promise = apis.studentsubjectlist(data);
    promise.then((response) => {
        this.isLoadingModel=false;
      if (response.data) {
        for (let Z = 0; Z < response.data.length; Z++) {
          if (response.data[Z].subject == "Mathematics") {
            this.Mathematics = response.data[Z].count;
            this.Mathematics_id = response.data[Z].mjmS_ID;
          }
          if (response.data[Z].subject == "Physics") {
            this.Physics = response.data[Z].count;
            this.Physics_id = response.data[Z].mjmS_ID;
          }
          if (response.data[Z].subject == "Chemistry") {
            this.Chemistry = response.data[Z].count;
            this.Chemistry_id = response.data[Z].mjmS_ID;
          }
          if (response.data[Z].subject == "Botany") {
            this.Botany = response.data[Z].count;
            this.Botany_id = response.data[Z].mjmS_ID;
          }
          if (response.data[Z].subject == "Zoology") {
            this.Zoology = response.data[Z].count;
            this.Zoology_id = response.data[Z].mjmS_ID;
          }
          if (response.data[Z].subject == "Introduction from Project Manager") {
            this.Introduction_From_Project_Manager = response.data[Z].count;
            this.Introduction_From_Project_Manager_id = response.data[Z].mjmS_ID;
          }
        }
      }
     
    });
  },
  async mounted() {},
  methods: {
    go_to_ui(data,val,id)
    {
    
    if(val>0)
    {
      this.$router.push({ name: 'studentlibrary', params: { subject_id: id } })
    }
    }

  },
};
</script>

<style></style>
